import React, { useEffect, useState } from 'react';
import {
  Box,
  Flex,
  ChakraProvider,
  theme,
  Slide,
  Heading,
  Text,
  Button,
  FormControl,
  FormLabel,
  Input,
  FormErrorMessage,
  Select,
  Textarea,
  useToast,
  Menu,
  MenuButton,
  Avatar,
  MenuList,
  MenuItem,
  FormHelperText,
  Modal,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  TableContainer,
  Thead,
  Tr,
  Th,
  Tbody,
  Link,
  ModalOverlay,
  Td,
  Table,
  IconButton,
} from '@chakra-ui/react';
import {
  GoogleLogin,
  googleLogout,
  GoogleOAuthProvider,
} from '@react-oauth/google';
import { FaChevronDown, FaWhatsapp } from 'react-icons/fa';
import { MdPhone } from 'react-icons/md';
import { FileInput } from './Components/FileInput';

function App() {
  const [loggedIn, setLoggedIn] = useState(false);
  const [idInvalid, setIdInvalid] = useState(false);
  const [phoneInvalid, setPhoneInvalid] = useState(false);
  const [filesInvalid, setFilesInvalid] = useState(false);
  const [filesError, setFilesError] = useState('');
  const [showContact, setShowContact] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [userInfo, setUserInfo] = useState({
    name: '',
    email: '',
    picture: '',
  });
  const toast = useToast();

  function verifyId(element) {
    setIdInvalid(!/^\d{4}[ABHPTS0-9]{4}\d{4}G$/i.test(element.target.value));
  }

  function verifyPhone(element) {
    setPhoneInvalid(!/^\d{10}$/i.test(element.target.value));
  }

  function verifyFiles(element) {
    if (element.target.files.length > 3) {
      setFilesError('Too many files selected!');
      setFilesInvalid(true);
    } else if (Array.from(element.target.files).some(file => file.size > 1e7)) {
      setFilesError(
        `File "${
          Array.from(element.target.files).find(file => file.size > 1e7).name
        }" exceeds 10 MiB`
      );
      setFilesInvalid(true);
    } else {
      setFilesInvalid(false);
    }
  }

  const transitionDuration = '250ms';

  return (
    <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
      <ChakraProvider theme={theme}>
        <Box width={'100vw'} height={'100vh'} boxSizing={'border-box'}>
          <Flex width={'100%'} height={'100%'}>
            <Box
              backgroundColor={'#DBECF6'}
              width={loggedIn ? '0' : { base: '100%', md: '70%' }}
              flexGrow={loggedIn ? 'unset' : '1'}
              transition={'all'}
              transitionTimingFunction={'ease-in-out'}
              transitionDuration={transitionDuration}
            >
              <Slide direction='left' in={!loggedIn}>
                <Flex
                  direction={'column'}
                  alignItems={'center'}
                  justifyContent={'center'}
                  height={'100%'}
                  maxWidth={{ base: '100vw', md: '80vw' }}
                  textAlign={'center'}
                >
                  <Heading my={'1rem'}>AUGSD Queries Portal</Heading>
                  <Text fontSize={'large'} my={'0.25rem'}>
                    Login with your BITS mail to continue
                  </Text>
                  <Box my={'1.5rem'}>
                    <GoogleLogin
                      size='large'
                      width={'fit-content'}
                      onSuccess={creds => {
                        const response = JSON.parse(
                          window.atob(creds.credential.split('.')[1])
                        );
                        if (response.hd === 'goa.bits-pilani.ac.in') {
                          setLoggedIn(true);
                          setUserInfo({
                            name: response.name,
                            email: response.email,
                            picture: response.picture,
                          });
                        } else {
                          toast({
                            title: 'Please sign in only with BITS mail',
                            status: 'error',
                          });
                        }
                      }}
                      text='continue_with'
                    />
                  </Box>
                </Flex>
              </Slide>
            </Box>
            <Box
              backgroundColor={'#EB5757'}
              width={loggedIn ? '0' : { base: '0', md: '15%' }}
              maxWidth={'10rem'}
              transition={'all'}
              transitionTimingFunction={'ease-in-out'}
              transitionDuration={transitionDuration}
            ></Box>
            <Box
              backgroundColor={'#FEDCAC'}
              width={loggedIn ? '100%' : { base: '0', md: '15%' }}
              maxWidth={loggedIn ? 'unset' : '10rem'}
              transition={'all'}
              transitionTimingFunction={'ease-in-out'}
              transitionDuration={transitionDuration}
            >
              <Slide direction='right' in={loggedIn} zindex={10}>
                <Flex
                  direction={'column'}
                  alignItems={'center'}
                  justifyContent={'space-between'}
                  height={'100%'}
                  width={'100%'}
                  overflowY={'auto'}
                >
                  <Flex
                    justifyContent={'flex-end'}
                    width={'100%'}
                    padding={'1rem'}
                  >
                    <Menu>
                      <MenuButton
                        as={Button}
                        rightIcon={<FaChevronDown />}
                        leftIcon={
                          <Avatar
                            src={userInfo.picture}
                            name={userInfo.name}
                            size='sm'
                          />
                        }
                      >
                        {userInfo.name}
                      </MenuButton>
                      <MenuList>
                        <MenuItem
                          onClick={() => {
                            googleLogout();
                            setLoggedIn(false);
                          }}
                        >
                          Sign Out
                        </MenuItem>
                      </MenuList>
                    </Menu>
                  </Flex>
                  <Flex
                    direction={'column'}
                    width={'100%'}
                    padding={'1rem'}
                    maxWidth={'30rem'}
                  >
                    <FormControl
                      onChange={verifyId}
                      isInvalid={idInvalid}
                      isRequired
                    >
                      <FormLabel>BITS ID</FormLabel>
                      <Input
                        type={'text'}
                        variant={'filled'}
                        data-field={'bitsId'}
                      />
                      <FormErrorMessage>Student ID is invalid</FormErrorMessage>
                    </FormControl>
                    <FormControl
                      onChange={verifyPhone}
                      isInvalid={phoneInvalid}
                      isRequired
                    >
                      <FormLabel>Contact number</FormLabel>
                      <Input
                        type={'tel'}
                        variant={'filled'}
                        data-field={'number'}
                      />
                      <FormHelperText>
                        10 digits, no spaces or hyphens, No +91
                      </FormHelperText>
                      <FormErrorMessage>
                        Contact number is invalid
                      </FormErrorMessage>
                    </FormControl>
                    <FormControl isRequired>
                      <FormLabel>Query Type</FormLabel>
                      <Select variant={'filled'} data-field={'type'}>
                        <option value={'Registration'}>Registration</option>
                        <option value={'Transcript'}>Transcript</option>
                        <option value={'General'}>General</option>
                      </Select>
                    </FormControl>
                    <FormControl isRequired>
                      <FormLabel>Query details</FormLabel>
                      <Textarea
                        variant={'filled'}
                        resize={'none'}
                        data-field={'details'}
                      />
                    </FormControl>
                    {/* <FormControl isInvalid={filesInvalid}>
                      <FormLabel>Select files related to query</FormLabel>
                      <FileInput
                        variant={'unstyled'}
                        data-field={'files'}
                        onChange={verifyFiles}
                      />
                      <FormHelperText>Max 3 files, 10 MB each</FormHelperText>
                      <FormErrorMessage>{filesError}</FormErrorMessage>
                    </FormControl> */}
                  </Flex>
                  <Flex
                    justifyContent={'space-around'}
                    width={'100%'}
                    padding={'1rem'}
                    maxWidth={'20rem'}
                  >
                    <Button onClick={() => setShowContact(true)}>
                      Contact us
                    </Button>
                    <Button
                      variant={'solid'}
                      colorScheme={'yellow'}
                      isLoading={buttonLoading}
                      onClick={() => {
                        const id = document.querySelector(
                          '[data-field=bitsId]'
                        ).value;
                        const phone = document.querySelector(
                          '[data-field=number]'
                        ).value;
                        const query =
                          document.querySelector('[data-field=type]').value;
                        const details = document.querySelector(
                          '[data-field=details]'
                        ).value;
                        // const files =
                        //   document.querySelector('[data-field=files]').files;
                        if (idInvalid || id === '') {
                          toast({
                            title: 'Please enter valid BITS ID',
                            status: 'error',
                          });
                        } else if (phoneInvalid || phone === '') {
                          toast({
                            title: 'Please enter valid contact number',
                            status: 'error',
                          });
                        } else if (details === '') {
                          toast({
                            title: 'Please enter details of query',
                            status: 'error',
                          });
                        } else if (filesInvalid) {
                          toast({
                            title: 'Please rectify the files selected',
                            status: 'error',
                          });
                        } else {
                          setButtonLoading(true);
                          fetch(process.env.REACT_APP_GOOGLE_APP_SCRIPT_URL, {
                            method: 'POST',
                            body: JSON.stringify({
                              name: userInfo.name,
                              email: userInfo.email,
                              id,
                              phone,
                              query,
                              details,
                            }),
                            headers: {
                              'Content-Type': 'text/plain;charset=utf-8',
                            },
                          })
                            .then(res => {
                              if (res.ok) {
                                setButtonLoading(false);
                                toast({
                                  title: 'Complaint lodged successfully',
                                  status: 'success',
                                });
                              }
                            })
                            .catch(err => {
                              console.log(err);
                              toast({
                                title: 'Could not register complaint',
                                status: 'error',
                              });
                            });
                          // axios
                          //   .post(
                          //     process.env.REACT_APP_GOOGLE_APP_SCRIPT_URL,
                          //     {
                          //       name: userInfo.name,
                          //       email: userInfo.email,
                          //       id,
                          //       phone,
                          //       query,
                          //       details,
                          //     },
                          //     {
                          //       headers: {
                          //         'Content-Type': 'multipart/form-data',
                          //         token: localStorage.getItem('token'),
                          //       },
                          //     }
                          //   )
                          //   .then(res => {
                          //     if (res.status === 200) {
                          //       toast({
                          //         title: 'Complaint lodged successfully',
                          //         status: 'success',
                          //       });
                          //     }
                          //     setButtonLoading(false);
                          //   })
                          //   .catch(err => {
                          //     setButtonLoading(false);
                          //     if (err.response.status === 401) {
                          //       toast({
                          //         title:
                          //           'Sign in timed out, please login again to continue',
                          //         status: 'error',
                          //       });
                          //       setLoggedIn(false);
                          //     } else if (err.response.status === 403) {
                          //       toast({
                          //         title:
                          //           'You can only raise two queries in a day',
                          //         status: 'error',
                          //       });
                          //     }
                          //   });
                        }
                      }}
                    >
                      Send query
                    </Button>
                  </Flex>
                </Flex>
              </Slide>
            </Box>
          </Flex>
        </Box>
        <Modal
          isOpen={showContact}
          onClose={() => setShowContact(false)}
          isCentered
          size={'lg'}
        >
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Contact details</ModalHeader>
            <ModalCloseButton />
            <ModalBody padding={'0.5rem'}>
              <TableContainer
                width={'100%'}
                display={'flex'}
                flexDirection={'column'}
                alignItems={'center'}
              >
                <Table size={{ base: 'sm', md: 'md' }} width={'fit-content'}>
                  <Thead>
                    <Tr>
                      <Th>Name</Th>
                      <Th>Contact number</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {[
                      { name: 'Keshav Pranshu', contact: '8595080757' },
                      { name: 'Sathvik Nama', contact: '7219254214' },
                      { name: 'Anushka Umarani', contact: '9742999978' },
                      { name: 'Darshna Songara', contact: '7987385811' },
                      { name: 'Aryan Saluja', contact: '9315273080' },
                      { name: 'Nishanth Lanka', contact: '9663935929' },
                      { name: 'Dhanashree Bhandarkar', contact: '7498654576' },
                    ].map(person => (
                      <Tr key={person.name}>
                        <Td>{person.name}</Td>
                        <Td>
                          <Link href={`tel:+91${person.contact}`}>
                            {person.contact}
                          </Link>
                          <Link href={`tel:+91${person.contact}`}>
                            <IconButton
                              aria-label={`Call ${person.name}`}
                              icon={<MdPhone />}
                              variant={'link'}
                              width={'1.5rem'}
                            />
                          </Link>
                          <Link href={`https://wa.me/91${person.contact}`}>
                            <IconButton
                              aria-label={`Text ${person.name} on WhatsApp`}
                              icon={<FaWhatsapp />}
                              variant={'link'}
                            />
                          </Link>
                        </Td>
                      </Tr>
                    ))}
                  </Tbody>
                </Table>
              </TableContainer>
            </ModalBody>
          </ModalContent>
        </Modal>
      </ChakraProvider>
    </GoogleOAuthProvider>
  );
}

export default App;
